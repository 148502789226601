import '../styles/landingPage.css';

import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { DAppProvider, Mainnet, Ropsten } from '@usedapp/core';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { SessionProvider as NextAuthSessionProvider } from 'next-auth/react';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import createEmotionCache from '../lib/emotion/createEmotionCache';
import theme from '../lib/mui/theme';
import { queryClient } from '../services/queryClient';
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const config = {
  // readOnlyChainId: Mainnet.chainId,
  readOnlyChainId: process.env.NEXT_PUBLIC_IMMUTABLE_LINK_ADDRESS?.indexOf('ropsten')
    ? Ropsten.chainId
    : Mainnet.chainId,
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <NextAuthSessionProvider
      session={pageProps.session}
      // Re-fetch session every 5 minutes
      refetchInterval={5 * 60}
      // Re-fetches session when window is focused
      refetchOnWindowFocus={false}
    >
      <DAppProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <CacheProvider value={emotionCache}>
            <SnackbarProvider maxSnack={3}>
              <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
              </Head>
              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...pageProps} />
              </ThemeProvider>
            </SnackbarProvider>
          </CacheProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </DAppProvider>
    </NextAuthSessionProvider>
  );
}
